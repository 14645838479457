.checkout__confirmation {
  .checkout__panel-wrapper:last-child {
    order: -1;
    flex: 1;
    padding-right: 35px;
  }
}

.checkout-page {
  display: flex;
  align-items: center;
  margin: 50px 0;
  min-height: 50vh;
  @include breakpoint-up('sm') {
    margin: 80px 0;
  }
  @include breakpoint-up('md') {
    margin: 50px 0;
  }
  .container {
    max-width: 1400px;
    > .checkout__header-main:not(.checkout__header-main--empty-cart) {
      display: none;
    }
  }
  .checkout__header-main--empty-cart {
    text-align: center;
    .checkout__continue-shopping-btn {
      margin: 20px auto;
    }
  }
  .checkout__panel-wrapper:first-child {
    @include breakpoint-up('lg') {
      max-width: 537px;
    }
    .checkout__panel {
      @include breakpoint-up('sm') {
        padding: 10px 35px;
      }
    }
  }
  .checkout__panel-wrapper:last-child {
    @include breakpoint-up('lg') {
      order: -1;
      flex: 1;
      padding-right: 35px;
    }
  }
  .order-item__title {
    font-size: 16px;
    @include breakpoint-up('sm') {
      font-size: 18px;
    }
  }
  .gift-card-form__btn,
  .discount-form__btn {
    @include breakpoint-up('sm') {
      max-width: 166px;
      min-width: 166px;
    }
  }
}

.checkout__layout,
.checkout__layout--flex {
  display: flex;
  flex-wrap: wrap;
}

.checkout__header-main {
  margin-bottom: 10px;
  @include breakpoint-up('sm') {
    margin-bottom: 30px;
  }
}

.checkout__panel-wrapper {
  width: 100%;
}

.checkout__panel {
  background-color: $pureWhite;
  border: 1px solid $fawn50-2;
  border-radius: 12px;
  padding: 10px 12px;
  margin-bottom: 25px;
  width: 100%;
  @include breakpoint-up('sm') {
    margin-bottom: 15px;
    padding: 20px 55px;
  }
}

.checkout__terms-panel {
  background-color: #f6f3ed ;
  padding-top: 10px;
  padding-bottom: 10px;
}

.checkout__row {
  border-bottom: 1px solid $fawn50-2;
  padding-top: 15px;
  padding-bottom: 15px;
  @include breakpoint-up('sm') {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.checkout__total-row {
  padding-top: 5px;
  padding-bottom: 5px;
}

.checkout__banner {
  border-radius: 12px;
  overflow: hidden;
  margin: 14px 0px;
}

.checkout__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  @include breakpoint-up('sm') {
    flex-direction: row;
  }
  &--flex-direction-column {
    flex-direction: column;
  }
  h3 {
    font-family: $bebas;
    font-size: 18px;
    letter-spacing: 0.02em;
    @include breakpoint-up('sm') {
      font-size: 24px;
    }
  }
  svg {
    width: 17px;
    transform: rotate(180deg);
  }
  &--closed {
    svg {
      transform: rotate(0deg);
    }
  }
  &--border-on-closed {
    border: none;
    &.checkout__header--closed {
      border-bottom: 1px solid $fawn50-2;
    }
  }
  p {
    font-size: 14px;
    margin-top: 10px;
    @include breakpoint-up('sm') {
      font-size: 16px;
    }
  }
}

.checkout__header-toggle-btn {
  display: flex;
  align-items: center;
  background: transparent;
  padding: 5px;
  border: none;
  margin-left: 10px;
  position: absolute;
  top: calc(50% - 9px);
  right: 0;
  @include breakpoint-up('sm') {
    padding: 10px;
    position: static;
  }
  svg {
    * {
      fill: $sitkablue;
    }
    width: 17px;
    height: 17px;
  }
}

.checkout__header-wrap {
  display: flex;
  align-items: center;
}

.checkout__header-subtotal {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-left: 10px;
  &.hide-on-desktop {
    @include breakpoint-up('sm') {
      display: none;
    }
  }
}

.checkout__navigation {
  margin-top: 48px;
}

.checkout__cta-btn {
  max-width: 392px;
  margin-left: auto;
}

.checkout_error_message {
  margin-left: auto;
  color: $error;
  font-size: 12px;
  margin-top: 5px;
  float: right;
  @include breakpoint-up('sm') {
    font-size: 14px;
  }
}

.checkout__continue-shopping-btn {
  max-width: 392px;
  margin-top: 20px;
}

.checkout__continue-shopping-link {
  text-decoration: underline;
  background-color: transparent;
  border: none;
}

.checkout__cart {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: auto;
}
/*
 checkout's checkboxes
*/

.checkout__checkbox-wrapper {
  font-size: 12px;
  font-weight: 400;
  margin: 15px 0;
  position: relative;
  @include breakpoint-up('sm') {
    font-size: 16px;
    font-weight: 500;
  }
  > label {
    display: inline-flex !important;
    cursor: pointer;
  }
}

.checkout__checkbox {
  border: 1px solid $black10 !important;
  width: 24px !important;
  height: 24px !important;
  border-radius: 2px;
  margin-right: 5px;
  svg {
    width: 12px;
    height: 12px;
    * {
      fill: $pureWhite;
    }
  }
  .checkbox--checked {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: $sitkablue;
    border-radius: 2px;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/*
 checkout's radio inputs
*/

.checkout__radio-wrapper {
  * {
    opacity: 0.85;
  }
}

.shipping-address-selector {
  .checkout__radio-wrapper,
  .checkout__secondary-radio-wrapper {
    > label {
      padding: 10px 15px;
    }
  }
}

.checkout__radio-wrapper,
.checkout__secondary-radio-wrapper {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
  position: relative;
  @include breakpoint-up('sm') {
    font-size: 16px;
  }
  &.is-selected {
    * {
      opacity: 1;
    }
  }
  > label {
    display: flex;
    align-items: center;
    border: 1px solid $black10;
    border-radius: 12px;
    padding: 0 15px;
    cursor: pointer;
    height: fit-content;
    min-height: 40px;
    @include breakpoint-up('sm') {
      min-height: 60px;
    }
    > span:nth-child(2) {
      width: 100%;
    }
  }
}

.checkout__secondary-radio-wrapper {
  margin-left: 24px;
  > label {
    min-height: 38px;
  }
}

.checkout__radio {
  border: 1px solid $sitkablue !important;
  width: 18px !important;
  height: 18px !important;
  border-radius: 100% !important;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  .radio--checked {
    position: absolute;
    top: calc(50% - 6px);
    left: calc(50% - 6px);
    width: 12px;
    height: 12px;
    background-color: $sitkablue;
    border-radius: 100% !important;
    cursor: pointer;
  }
}

.checkout__radio-label,
.checkout__secondary-radio-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.checkout__secondary-radio-label {
  * {
    opacity: 0.75 !important;
  }
}

/*
 checkout's basic input overrides
*/

.checkout__panel {
  .input-group--wrapper {
    .input-group {
      width: 100%;
      @include breakpoint-up('md') {
        width: calc(50% - 6.5px);
      }
    }
  }
  .input-group {
    // height: 48px;
    margin-bottom: 12px;
    @include breakpoint-up('sm') {
      // height: 60px;
    }
  }
}

.shopping-btn-mobile {
  display: none;

  @include breakpoint-up('lg') {
    display: flex;
  }
}

.show-on-mobile {
  display: flex;

  @include breakpoint-up('lg') {
    display: none;
  }
}

.checkout__discount-wrap {
  width: 100%;
  margin-top: 0px;
  h3 {
    font-size: 15px;
    margin-bottom: 10px;
    @include breakpoint-up('sm') {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  p,
  a {
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 10px;
    @include breakpoint-up('sm') {
      margin-bottom: 20px;
    }
    @include breakpoint-down('sm') {
      font-size: 15px;
      line-height: 18px;
    }
    &:last-child {
      margin: 0;
    }
  }
  a {
    display: inline;
    font-weight: 500;
    color: $salmon;
    text-decoration: underline;
    &:hover {
      color: $sitkablue;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 8px;
  border-radius: 6px;

  /* Positioning */
  position: absolute;
  z-index: 1;
  bottom: 125%; 
  left: 50%;
  margin-left: -120px;

  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

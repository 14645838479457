@import '@/styles/_shared';

.header {
  position: sticky;
  top: 0;
  z-index: 2000;
  transition: 0.5s ease-in-out transform;
  transform: translateY(-150%);
  &.is-visible {
    transform: translateY(0)
  }
}

.secondaryNavigationContainer {
  border-bottom: 1px solid #7a7373;
  border-top: 1px solid #7a7373;
  display: flex;
  align-items: center;
  height: 48px;
  margin: 0 auto;
  margin-top: 0;
  background-color: $sitkablue;
  width: 100%;
  padding: 0px 32px;
  position: sticky;
  top: 0;
  z-index: 1900;
  transition: top 0.3s ease-in-out;

  a {
    color: white;
  }
}

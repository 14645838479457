// ===========================================================================
// *** Shared (Variables - Functions - Mixins)
// ===========================================================================
@import './shared.scss';

// ===========================================================================
// *** Utilities
// ===========================================================================
@import './utility/reset.scss';
@import './utility/_breakpoints.scss';
@import './utility/_container.scss';
@import './utility/_inputs.scss';
@import './utility/normalize.scss';
@import './utility/_typography.scss';
@import './utility/_ui-elements.scss';
@import './utility/_utility.scss';
@import './utility/_container.scss';

// ===========================================================================
// *** Vendor
// ===========================================================================
@import './vendor/react-dropdown.scss';

// ===========================================================================
// *** Headless Checkout
// ===========================================================================
@import './checkout/index.scss';

* {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

body {
  color: $sitkablue;
  font-family: $frank-ruhl;
  background-color: $alabaster2;
}

.pac-container {
  z-index: 10000 !important;
}

.image-swiper-button-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 7%;
  transform: rotate(-90deg);
  background-color: #b7cd96;
  border-radius: 24px;
  width: 24px;
  height: 24px;
}

.image-swiper-button-prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 7%;
  transform: rotate(90deg);
  background-color: #b7cd96;
  border-radius: 24px;
  width: 24px;
  height: 24px;
}

.confirmation__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.confirmation__list-item {
  width: 100%;
  margin: 20px 0 0;
  @include breakpoint-up('sm') {
    width: calc(50% - 20px);
    margin: 20px 0;
  }
  h3 {
    font-family: $bebas;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    @include breakpoint-up('sm') {
      font-size: 16px;
    }
  }
}
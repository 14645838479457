.btn {
  font-weight: 500;
  color: $alabaster2;
  background-color: $black;
  border: none;
  padding: 13px 0;
  border-radius: 48px;
  min-width: 250px;
  height: 48px;
  text-decoration: none;
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out background;

  &:disabled {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6)
      ),
      $black;
    pointer-events: none;
  }

  &.copper {
    background-color: $copper;

    &:hover {
      background-color: $salmon;
    }

    &:disabled {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0.6)
        ),
        $salmon;
    }
  }

  &.salmon {
    background-color: $salmon;

    &:hover {
      background-color: $copper;
    }

    &:disabled {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0.6)
        ),
        $copper;
    }
  }

  &.sitkablue {
    background-color: $sitkablue;

    &:hover {
      background: $sitkabluelight;
    }

    &:disabled {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0.6)
        ),
        $sitkabluelight;
    }
  }

  &.alabaster {
    background-color: $alabaster;
    color: $sitkablue;

    &:disabled {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0.6)
        ),
        $alabaster;
    }
  }

  &.pureWhite {
    background-color: $pureWhite;
    color: $sitkablue;

    &:hover {
      background: $sitkablue;
      color: $pureWhite;
    }
  }

  &.fawn {
    background-color: $fawn;
    color: $sitkablue;

    &:hover {
      background: $fawn50;
    }

    @include breakpoint-up('sm') {
      background-color: transparent;
    }

    &:disabled {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0.6)
        ),
        $fawn;
    }
  }

  &--only-mobile {
    border: none;
    padding: 13px 0;
    border-radius: 48px;
    min-width: 250px;
    width: 100%;
    display: block;

    @include breakpoint-up('sm') {
      padding: 0;
      text-decoration: underline;
      min-width: 0;
      border-radius: 0;
    }

    &:disabled {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 0.6)
        ),
        $black;
    }

    &.salmon {
      background-color: $salmon;

      @include breakpoint-up('sm') {
        background-color: transparent;
      }

      &:hover {
        background-color: $copper;
      }

      &:disabled {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
          ),
          $copper;
      }
    }

    &.sitkablue {
      background-color: $sitkablue;

      @include breakpoint-up('sm') {
        background-color: transparent;
      }

      &:hover {
        background: $sitkabluelight;
      }

      &:disabled {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
          ),
          $sitkabluelight;
      }
    }

    &.alabaster {
      background-color: $alabaster;
      color: $sitkablue;

      @include breakpoint-up('sm') {
        background-color: transparent;
      }

      &:disabled {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
          ),
          $alabaster;
      }
    }
  }
}

a {
  &:not(.btn):hover {
    color: $salmon;
    text-decoration: underline;
  }

  &.btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn-link-underline {
  font-weight: 500;
  color: $sitkablue;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  border: none;
  box-shadow: none;
  transition: 0.2s ease-in-out color;

  &:hover {
    color: $salmon;
  }
}

.u-error {
  color: $error;
}

.projected-slider {
  .swiper-button-prev {
    @include breakpoint-up('sm') {
      top: 30% !important;
    }
  }

  .swiper-button-next {
    @include breakpoint-up('sm') {
      top: 30% !important;
    }
  }
}

.swiper {
  .swiper-button-prev,
  .swiper-button-next,
  .swiper-button-disabled {
    margin: 0;
    top: 0;
    position: absolute;
    color: #fff;
    background: rgb(0, 0, 0);
    height: 100%;
  }
  .swiper-button-disabled {
    background: none !important;
  }
  .swiper-button-prev {
    padding-left: 14px;
    left: 0;
    background: linear-gradient(
      -90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }
  .swiper-button-next {
    padding-right: 10px;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }
}

.fishermen-swiper {
  &.swiper {
    width: 100%;

    .swiper-button-prev {
      left: 0;
      top: 40%;
      color: $sitkablue;
      width: initial;
      width: 50px;
      height: 80px;
      border-radius: 0px 100px 100px 0px;
      background: none;

      @include breakpoint-up('sm') {
        height: 100px;
        width: 80px;
      }

      &:after {
        font-size: 34px;
      }

      &:hover {
        color: lighten($sitkablue, 25%);
      }
    }

    .swiper-button-next {
      right: 0;
      top: 40%;
      color: $sitkablue;
      width: initial;
      width: 50px;
      height: 80px;
      border-radius: 100px 0px 0px 100px;
      background: none;

      @include breakpoint-up('sm') {
        height: 100px;
        width: 80px;
      }

      &:after {
        font-size: 34px;
      }
      &:hover {
        color: lighten($sitkablue, 25%);
      }
    }
  }

  .swiper-wrapper {
    position: static;
  }
}

.container {
  width: calc(100% - 60px);
  max-width: 1680px;
  margin: 0 auto;
  @include breakpoint-up('sm') {
    width: calc(100% - 100px);
  }
  @include breakpoint-up('md') {
    width: calc(100% - 120px);
  }
  @include breakpoint-up('lg') {
    width: calc(100% - 170px);
  }
  @include breakpoint-up('xl') {
    width: calc(100% - 240px);
  }
  &.container--no-max-width {
    max-width: none
  }
}
.dropdown-selector {
  position: relative;
  margin-bottom: 25px;
  cursor: pointer;
  .dropdown-selector__arrow-closed,
  .dropdown-selector__arrow-open {
    width: 15px;
    position: absolute;
    top: 20px;
    right: 12px;
    z-index: 1001;
    @include breakpoint-up('sm') {
      width: 23px;
      top: 24px;
      right: 24px;
    }
  }
  .dropdown-selector__arrow-open {
    transform: rotate(180deg);
    * {
      fill: $salmon;
    }
  }
  .Dropdown-control {
    border-radius: 12px;
    padding: 0;
    cursor: pointer;
    > div:first-child {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 12px;
      background-color: $alabaster;
      @include breakpoint-up('sm') {
        height: 60px;
        padding: 0 24px;
      }
    }
  }
  .Dropdown-menu {
    display: flex;
    flex-direction: column;
    top: 0;
    border-radius: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(45, 43, 38, 0.1);
    max-height: none;
  }

  .Dropdown-placeholder,
  .Dropdown-option {
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 12px;
    background-color: $alabaster;
    color: $sitkablue;
    @include breakpoint-up('sm') {
      font-size: 18px;
      height: 60px;
      padding: 0 24px;
    }
  }

  .Dropdown-option {
    &.is-selected {
      background: $sitkablue;
      color: $alabaster2;
      order: -1;
    }
    &:hover:not(.is-selected) {
      background-color: $alabaster;
      color: $sitkablue;
    }
  }
}

.checkout-dropdown-label {
  font-size: 12px;
  font-weight: 400;
  color: rgba(22, 49, 68, 0.6);
  text-transform: capitalize;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 15px;
}

.new-checkout-dropdown-selector {
  svg {
    width: 12px;
  }
  .react-select__control {
    border-radius: 12px;
    border: 1px solid $black10;
    padding: 0;
    box-shadow: none !important;
    height: 48px;
    @include breakpoint-up('sm') {
      height: 60px;
    }
  }
  .react-select__value-container {
    padding: 20px 0 0 15px;
    @include breakpoint-up('sm') {
      padding: 25px 0 0 15px;
    }
  }
  .react-select__placeholder,
  .react-select__single-value {
    color: $sitkablue;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    @include breakpoint-up('sm') {
      font-size: 16px;
    }
  }
  .react-select__input {
    margin: 0;
    padding: 0;
  }
  .react-select__indicators {
    .react-select__indicator-separator {
      display: none;
    }
    .react-select__indicator {
      width: 46px;
      height: 36px;
      border-left: 1px solid $black10;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .react-select__menu {
    top: -8px;
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }
  .react-select__menu-list {
    padding: 0;
    border-radius: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(45, 43, 38, 0.1);
  }
  .react-select__option {
    font-size: 14px;
    font-weight: 500;
    padding: 21px 15px;
    // background-color: $alabaster;
    // color: $sitkablue;
    @include breakpoint-up('sm') {
      font-size: 16px;
    }
    &:hover {
      background: $sitkablue;
      color: $alabaster2;
    }
  }
}

.checkout-dropdown-selector {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  .dropdown-selector__arrow-closed,
  .dropdown-selector__arrow-open {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $black10;
    width: 46px;
    height: 36px;
    position: absolute;
    top: calc(50% - 18px);
    right: 0;
    // z-index: 1001;
    svg {
      width: 12px;
    }
  }
  .dropdown-selector__arrow-open {
    transform: rotate(180deg);
  }
  .Dropdown-control {
    border-radius: 12px;
    padding: 0;
    cursor: pointer;
    border: 1px solid $black10;
    > div:first-child {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 20px 15px 0;
      background-color: $pureWhite;
      @include breakpoint-up('sm') {
        height: 60px;
        padding: 25px 15px 0;
      }
    }
  }
  .Dropdown-menu {
    display: flex;
    flex-direction: column;
    top: 0;
    border-radius: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(45, 43, 38, 0.1);
  }

  .Dropdown-placeholder,
  .Dropdown-option {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 21px 15px;
    background-color: $alabaster;
    color: $sitkablue;
    @include breakpoint-up('sm') {
      font-size: 16px;
    }
  }

  .Dropdown-option {
    &.is-selected {
      background: $sitkablue;
      color: $alabaster2;
      order: -1;
    }
    &:hover:not(.is-selected) {
      background-color: $alabaster;
      color: $sitkablue;
    }
  }
}
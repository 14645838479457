@import '@/styles/_shared.scss';

.search-flyout {
    .search-flyout__content--enter {
        // transform: translateY(492px);

        // @media screen and (min-width: 653px) {
        //     transform: translateY(479px);
        // }

        // @include breakpoint-up('md') {
        //     transform: translateY(477px);
        // }
    }

    .search-flyout__content--enter-active {
        // transform: translateY(0);
        top: 0;
    }

    .search-flyout__content--enter-done {
        top: 0;
        // transform: translateY(492px);

        // @media screen and (min-width: 653px) {
        //     transform: translateY(479px);
        // }

        // @include breakpoint-up('md') {
        //     transform: translateY(477px);
        // }
    }

    .search-flyout__content--exit {
        // transform: translateY(-492px);

        // @media screen and (min-width: 653px) {
        //     transform: translateY(-479px);
        // }

        // @include breakpoint-up('md') {
        //     transform: translateY(-477px);
        // }
    }
}

.search-flyout {
    width: 100%;
    height: 100%;
    z-index: 2001;
    position: fixed;
    overflow: hidden;

    @include breakpoint-up('md') {
        overflow-y: scroll;
    }

    > :global(.btn) {
        min-width: none;
        border-radius: 48px;
    }

    .indicies {
        width: 100%;

        @include breakpoint-up('md') {
            width: 656px;
        }
    }

    // search-flyout__content
    &__content {
        position: relative;
        padding-bottom: 53px;
        height: 100vh;

        @include breakpoint-up('md') {
            height: initial;
        }
    }

    .searchbox {
        position: relative;
        height: 48px;

        svg {
            width: 17.3px;
            height: 15.9px;
            position: absolute;
            top: 16.11px;
            left: 24.37px;
        }

        input {
            border: 1px solid $fawn50-2;
            padding: 12px 0 12px 61px;
            width: 100%;
            min-height: 48px;
            border-radius: 12px;
        }
    }

    .content {
        padding: 24px 7.46% 0 7.46%;

        @include breakpoint-up('sm') {
            padding: 18px 10% 0 10%;
        }

        @include breakpoint-up('lg') {
            padding: 18px 0;
            max-width: 1106px;
            margin: auto;
        }
    }

    .search-results {
        margin-top: 42px;

        > :global(.search-results__header) {
            font-size: fontsize(21px);
        }

        @include breakpoint-up('md') {
            display: flex;
            width: 100%;
        }

        h4 {
            margin-right: 8.85%;
        }

        ul {
            list-style: none;
            margin-top: 12px;

            @include breakpoint-up('md') {
                margin-top: 0;
            }

            li {
                margin-bottom: 12px;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .search-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            background-color: $salmon;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            padding: 0;
            border-radius: 100%;

            @include breakpoint-up('md') {
                display: none;
            }
        }

        svg {
            height: 17px;
            width: 17px;
            z-index: 1;
        }

        .searchbox-wrap {
            width: 86%;
        }
    }

    .searchbox-close {
        display: none;
        z-index: 1000;

        @include breakpoint-up('md') {
            position: absolute;
            top: 24px;
            right: 6.25%;
            display: block;
        }

        button {
            background-color: $salmon;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            padding: 0;
            border-radius: 100%;
        }

        svg {
            height: 17px;
            width: 17px;
        }
    }
}

.search-flyout__close-btn {
    background-color: transparent;
    border: none;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;

    @include breakpoint-up('md') {
        top: 15px;
        right: 15px;
    }

    svg {
        width: 14px;

        @include breakpoint-up('sm') {
            width: 20px;
        }

        @include breakpoint-up('md') {
            width: 27px;
        }

        * {
            fill: $sitkablue;
        }
    }
}

.search-flyout__overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    cursor: pointer;
}

.search-flyout__content {
    width: 100%;
    top: -100%;
    min-height: 444px;
    background-color: $alabaster2;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
    // transform: translateY(-492px);
    transition: 0.2s ease-in-out top;
    overflow-y: auto;
}
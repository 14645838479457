@import '@/styles/_shared.scss';
.desktop-block {
  display: block !important;
}
.desktop-flex {
  display: flex !important;
}
.mobile-block {
  display: none !important;
}
.mobile-flex {
  display: none !important;
}

@mixin breakpoint($size) {
  @include breakpoint-down($size) {
    .#{$size}.desktop-block {
      display: none !important;
    }
    .#{$size}.desktop-flex {
      display: none !important;

    }
    .#{$size}.mobile-flex {
      display: flex !important;
    }
    .#{$size}.mobile-block {
      display: block !important;
    }
  }
}
@include breakpoint(xxs);
@include breakpoint(xs);
@include breakpoint(sm);
@include breakpoint(md);
@include breakpoint(lg);
@include breakpoint(xl);
@include breakpoint(xxl);

@import '@/styles/_shared';

.order-discount-form {
  p {
    font-size: 12px;
    @include breakpoint-up('sm') {
      font-size: 14px;
    }
  }
}

.order-discount-form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  h3 {
    font-size: 14px;
    color: $sitkablue;
    @include breakpoint-up('sm') {
      font-size: 16px;
      color: $sitkablue;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    @include breakpoint-up('sm') {
      width: 25px;
      height: 25px;
    }
    * {
      fill: $sitkablue;
      @include breakpoint-up('sm') {
        fill: $sitkablue;
      }
    }
  }
}

.discount-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  @include breakpoint-up('sm') {
    flex-direction: row;
  }
  .input-group {
    margin: 0;
    height: 48px;
  }
  .input-field__input {
    height: 48px;
  }
}

.applied-discount-list {
  display: flex;
  flex-direction: row;

  .applied-discount {
    text-align: right;
    vertical-align: middle;
    background-color: $fawn50-2;
    border-radius: 20px;
    padding: 6px;
    font-size: 12px;
    margin-right: 6px;

    button {
      display: inline-flex;
      align-items: center;
      background: none;
      border: none;
      position: relative;
      top: 2px;
      padding: unset !important;
      margin-right: 2px;

      svg {
        max-height: 12px;
      }
    }
  }
}

.order-summary-item__description {
  text-align: right;
  margin-left: 10px;
  vertical-align: middle;
}

.discount-form__btn {
  max-width: 253px;
  margin: 12px 0 0;
  @include breakpoint-up('sm') {
    margin: 0 0 0 20px;
  }
}

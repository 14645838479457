.order-customer__header-links {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  @include breakpoint-up('sm') {
    margin-top: 0;
    align-items: flex-end;
  }
}

.order-customer__header-link {
  font-size: 14px;
  a,
  .btn-link-underline {
    font-size: 14px;
    color: $salmon;
    text-decoration: underline;
    &:hover {
      color: $sitkablue;
    }
  }
}

.order-giftnote-disclaimer {
  font-size: 11px;
}

.order-customer-account-form {
  display: flex;
  flex-direction: row;
  @include breakpoint-down('xs') {
    flex-direction: column-reverse;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    margin: auto 0;
    > form > div {
      flex-direction: column !important;
    }
    @include breakpoint-up('sm') {
      width: 50%;
    }
  }
  .divider {
    padding: 0px;
    margin: auto 28px;
    border: 1px solid #dfdfdf;
    height: 250px;
    width: 0px;
    @include breakpoint-down('xs') {
      height: 0px;
      width: 85%;
      margin: 28px auto;
    }
    h3 {
      margin: auto 0;
      @include breakpoint-down('xs') {
        display: none;
      }
    }
  }
  h3 {
    font-family: $bebas;
    letter-spacing: 0.02em;
    margin-bottom: 15px;
  }
  .btn {
    margin: 24px 0;
    max-width: 392px;
  }
}

.order-customer-account-form.guest {
  > div {
    width: 100%;
  }
}

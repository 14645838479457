.header {
  position: sticky;
  background-color: #003366;
  color: white;
  top: 0;
  z-index: 2000;
  transition: 0.5s ease-in-out transform;
  transform: translateY(-150%);
  &.is-visible {
    transform: translateY(0)
  }
}
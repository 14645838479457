@import '@/styles/_shared.scss';

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid $fawn;
  padding: 12px 16px 12px 12px;
  position: relative;
  height: 100%;
  margin-bottom: 25px;

  > :global(.btn) {
    min-width: none !important;
    border-radius: 48px;
  }

  .card__inner {
    width: 100%;

    @include breakpoint-up('md') {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .card__image {
    @include breakpoint-up('md') {
      width: 47%;
    }
  }

  .card__content {
    @include breakpoint-up('md') {
      width: 47%;
    }
  }

  .best-seller {
    text-align: center;
    background-color: $sitkablue;
    color: #fff;
    border-radius: 48px;
    min-height: 25px;
    min-width: 156px;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 736px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .metafield {
    margin-top: 17.41px;
    line-height: 140%;
    font-weight: 500;
  }

  .media {
    margin-bottom: 25px;
    display: block;
    border-radius: 12px;

    @include breakpoint-up('md') {
      margin-bottom: 0;
    }
  }

  // card__content
  &__content {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      margin-bottom: 12px;
    }

    .price-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .compare {
      text-decoration: line-through;
    }

    a {
      margin-top: 16px;
    }
  }
}

.media {
  width: 100%;
  overflow: hidden;
}

.image {
  width: 100%;
  border-radius: 12px;
}

html {
  &.disable-scroll {
    overflow: hidden;
  }
}

.position {
  &--absolute {
    position: absolute;
  }

  &--relative {
    position: relative;
  }

  &--fixed {
    position: fixed;
  }

  &--static {
    position: static;
  }

  &--sticky {
    position: sticky;
  }
}

.display {
  &--block {
    display: block;
  }

  &--inline {
    display: inline;
  }

  &--inline-block {
    display: inline-block;
  }

  &--flex {
    display: flex;
  }

  &--inline-flex {
    display: inline-flex;
  }

  &--none {
    display: none;
  }
}

.text-align {
  &--left {
    text-align: left;
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}

.aspect-ratio {
  position: relative;
  display: block;
  width: 100%;
  height: 0;

  // square
  &--1-1 {
    padding-top: 100%;
  }

  // horizontal
  &--4-3 {
    padding-top: 75%;
  }

  &--3-2 {
    padding-top: 66.66%;
  }

  &--8-5 {
    padding-top: 62.5%;
  }

  &--16-9 {
    padding-top: 56.25%;
  }

  &--3-1 {
    padding-top: 33.33%;
  }

  // vertical
  &--4-5 {
    padding-top: 125%;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  &--align-start {
    align-items: flex-start;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--flush {
    padding-right: 0;
    padding-left: 0;

    & > [class^='col-'] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &--no-padding {
    padding-right: 0;
    padding-left: 0;
  }

  &--justify-around {
    justify-content: space-around;
  }

  &--justify-between {
    justify-content: space-between;
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--direction-column {
    flex-direction: column;
  }

  &--direction-reverse {
    flex-direction: row-reverse;
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }
}

.bg-color--fawn {
  background-color: $fawn;
}
@import '@/styles/_shared.scss';

.order-item {
  padding: 25px 0;
  @include breakpoint-up('sm') {
    padding: 35px 0;
  }
  .order-item__title-link {
    margin-right: 4px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.title-subscription-box {
  &:hover {
    color: $sitkablue;
    cursor: default;
  }
}

.image--subscription-box {
  &:hover {
    color: $sitkablue;
    cursor: default;
  }
}

.order-item__main {
  display: flex;
  justify-content: flex-start;

  .order-item__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .order-item__main-details {
      display: flex;
      justify-content: space-between;
    }
  }
}

.order-item__title {
  font-family: $bebas;
  letter-spacing: 0.02em;
}

.order-item__image {
  flex-basis: 100px;
  margin-right: 10px;
  @include breakpoint-up('sm') {
    flex-basis: 130px;
    margin-right: 15px;
  }
}

.order-item__quantity-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $fawn50-2;
  border-radius: 20px;
  &--quantity-only {
    width: fit-content;
    display: inline-flex;
    padding: 4px;
  }
}

.order-item__decrement-btn,
.order-item__increment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 0.5px solid $sitkablue;
  padding: 0;
  margin: 4px;
  background-color: $pureWhite;
  svg {
    width: 8px;
    height: 8px;
    * {
      stroke: $sitkablue;
    }
  }
}

.order-item__remove-btn {
  display: inline-flex;
  background: $pureWhite;
  border: none;
  align-items: center;
  .checkout__layout.checkout__confirmation & {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    width: 14px;
    height: 14px;
  }
}

.order-item__quantity {
  font-size: 12px;
  margin: 0 2px;
  width: max-content;
}

.order-item__disclaimer {
  width: 100%;
  margin-top: 20px;
  h3 {
    font-size: 14px;
    margin-bottom: 10px;
    @include breakpoint-up('sm') {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  p,
  a {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 10px;
    @include breakpoint-up('sm') {
      margin-bottom: 20px;
    }
    @include breakpoint-down('sm') {
      font-size: 12px;
      line-height: 18px;
    }
    &:last-child {
      margin: 0;
    }
  }
  a {
    display: inline;
    font-weight: 500;
    color: $salmon;
    text-decoration: underline;
    &:hover {
      color: $sitkablue;
    }
  }
}

.order-item__error {
  width: 100%;
  margin-top: 20px;
  background: $error;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  p {
    color: $pureWhite;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    @include breakpoint-up('sm') {
      margin-bottom: 20px;
    }
    &:last-child {
      margin: 0;
    }
  }
}

.order-item__price {
  display: flex;
  flex-direction: column;
  text-align: right;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 4px;
  }

  .original-price,
  .price {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
  .original-price {
    font-weight: unset;
    color: #16314452;
    text-decoration: line-through;
  }
  .price-login-prompt,
  .price-per-unit {
    font-weight: 400;
    line-height: 14px;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
  }
  .price-per-unit {
    font-size: 12px;
  }
  .price-login-prompt {
    font-size: 11px;
    @include breakpoint-down('xs') {
      display: none;
    }
  }
}

.order-item__weight {
  font-size: 14px;
  line-height: 18px;
}

.order-item__discounts {
  display: flex;
  flex-direction: row;
  @include breakpoint-down('sm') {
    display: none;
  }

  .discount-pill {
    text-align: center;
    vertical-align: middle;
    background-color: $newYeller;
    border-radius: 20px;
    padding: 6px;
    font-size: 12px;
    height: fit-content;
  }
}

.order-item__options,
.order-item__options--mobile {
  margin-top: 12px;
  font-size: 14px;
  .order-item__option-detail {
    display: flex;
    line-height: 20px;
    div:last-child {
      margin-left: auto;
    }
  }
}

.order-item__options {
  @include breakpoint-down('sm') {
    display: none;
  }
  &--mobile {
    @include breakpoint-up('md') {
      display: none;
    }
  }
}

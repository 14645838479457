.FieldSet--ShippingAddress .CheckoutStep__FieldSetInfo {
  display: block;
}

.FieldSet--ShippingAddress .CheckoutStep__FieldSetInfo--Secondary {
  color: rgba(0, 0, 0, 0.65);
}

.FieldSet--ShippingAddress .RadioButton {
  border-width: 1px 1px 0px 1px;
  border-color: #D9D9D9;
  border-style: solid;
  margin-bottom: 0;
  min-height: 60px;
  padding: 10px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.FieldSet--ShippingAddress .RadioButton:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.FieldSet--ShippingAddress .RadioButton:last-child {
  border-width: 1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.FieldSet--ShippingAddress .RadioButton .stx-radio-field__label {
  height: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 100%;
}

.FieldSet--ShippingAddress .RadioButton .stx-field {
  position: unset;
}

.FieldSet--ShippingAddress .RadioButton .stx-radio {
  position: unset;
}

.FieldSet--ShippingAddress .RadioButton .stx-radio-field {
  display: flex;
  align-items: center;
}

.FieldSet--ShippingAddress .RadioField {
  margin-bottom: 0;
  padding-left: 0;
}

.FieldSet--AddressNew {
  background: #fafafb;
  border: 1px solid #d9d9d9;
  padding: 20px;
}

.input-field {
  position: relative;
  flex: 1;
  width: 100%;

  &__container {
    width: 100%;
  }

  &--disabled {
    opacity: 0.4;
  }

  &__input {
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    border: 1px solid $black10;
    padding: 0 12px;
    @include breakpoint-up('sm') {
      font-size: 16px;
      height: 60px;
      padding: 0 15px;
    }
  }

  &__input:focus + &__label,
  &__input:not([value=""]) + &__label,
  &__input:-webkit-autofill + &__label {
    top: 12px;
    font-size: 12px;
  }

  &__label {
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    @include breakpoint-up('sm') {
      font-size: 18px;
    }
  }

  &__message {
    font-size: 14px;
    margin-top: 20px;
  }

  &--alert {
    .input-field__input {
      border-color: $error;
    }
    .field__message {
      color: $error;
    }
  }

  &__textarea {
    height: 80px;
    resize: none;
    overflow-wrap: break-word;
    padding-top: 10px;
  }
}

.field__message {
  font-size: 12px;
  margin-top: 5px;
  @include breakpoint-up('sm') {
    font-size: 14px;
  }
}

.input-field__input {
  color: $sitkablue;
  &::placeholder {
    font-weight: 400;
    color: rgba(22, 49, 68, 0.6);
	}
}
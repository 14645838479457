.order-summary__header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  h3 {
    font-family: $bebas;
    letter-spacing: 0.02em;
  }
  svg {
    width: 17px;
    transform: rotate(180deg);
  }
  &--closed {
    svg {
      transform: rotate(0deg);
    }
    border: none;
  }
}

.order-summary__breakdown {
  padding-top: 10px;
  .breakdown__section {
    &.breakdown__section--total {
      border: none;
    }
  }
}

.free-shipping-amount {
  font-weight: 500;
  color: $sitkablue;
}

@include breakpoint-down('xs') {
  .free-shipping-amount {
    font-size: 14px;
  }
}

.order-summary__item {
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  margin-bottom: 10px;
  @include breakpoint-up('sm') {
    font-size: 14px;
  }
  &:last-child {
    margin: 0;
  }
  .breakdown__section--total & {
    font-family: $bebas;
    font-size: 21px;
    @include breakpoint-up('sm') {
      font-size: 28px;
    }
  }

  .order-summary__item-amount.zero {
    color: $salmon;
  }
}

.order-summary__item-title {
  font-weight: 500;
  letter-spacing: 0.02em;
}

.order-summary-item__wrapper {
  display: flex;
  justify-content: flex-end;

  .order-summary-item__amount,
  .order-summary-item__description {
    text-align: right;
    margin-left: 10px;
    vertical-align: middle;
  }

  .order-summary-item__amount,
  .order-summary-item__amount p {
    font-size: inherit;
  }

  .order-summary-item__action {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    position: relative;
    top: 2px;
  }
}

.order-summary__amount {
  font-size: 14px;
}

.field-set--empty-state {
  border: 1px solid $black10;
  border-radius: 12px;
  margin-bottom: 0;
  height: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  background: $alabaster;
  color: $sitkablue;
  @include breakpoint-up('sm') {
    font-size: 14px;
    line-height: 20px;
    height: 60px;
    padding: 0 15px;
  }
}

.field-set--empty-state svg {
  width: 24px;
  margin-right: 12px;
  @include breakpoint-up('sm') {
    margin-right: 17px;
  }
}

.order-shipping-method {
  margin-bottom: 20px;
}

.shipping-method-footnote {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  p {
    @include breakpoint-up('sm') {
      font-size: 12px;
    }
    font-size: 11px;
    sup {
      line-height: unset;
    }
  }
}

.shipping-method-selector,
.secondary-shipping-method-selector {
  .checkout__radio-wrapper.is-selected,
  .checkout__secondary-radio-wrapper.is-selected {
    > label {
      border: 1px solid $success;
      background: rgba(70, 124, 82, 0.1);
    }
  }

  .checkout__radio-wrapper.is-visible,
  .checkout__secondary-radio-wrapper.is-visible {
    * {
      opacity: 1;
    }
  }

  .checkout__secondary-radio-label,
  .checkout__secondary-radio-wrapper {
    @include breakpoint-down('xs') {
      font-size: 12px;
    }
  }
}

.secondary-shipping-method-selector {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  max-height: 0;
  height: auto;
  overflow: hidden;
}

.secondary-shipping-method-selector.is-visible {
  max-height: 180px;
}

.field-set__content {
  color: $sitkablue;
  svg {
    width: 24px;
  }
}

@import '@/styles/_shared';

@media print {
  .mainNavigation {
    display: none !important;
  }
}

.mainNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $alabaster2;
  height: 48px;
  position: relative;
  @include breakpoint-up('sm') {
    height: 72px;
  }
  @include breakpoint-up('md') {
    height: 84px;
  }
  &.purchase-flow {
    background-color: $alabaster;
    .navButton {
      display: none;
    }
    .navItems {
      width: 20%;
    }
    .navItemAccount {
      @include breakpoint-down('sm') {
        display: none;
      }
    }
    .accountMenu,
    .customerServiceInfo {
      background-color: $alabaster;
    }
  }
  &.checkout {
    background-color: $alabaster;
    .accountMenu,
    .customerServiceInfo {
      background-color: $alabaster;
    }
    .navButton {
      display: none;
    }
  }
  .mainNavContainer {
    display: flex;
    justify-content: space-between;
    @include breakpoint-down('lg') {
      width: calc(100% - 72px);
    }
    @include breakpoint-down('sm') {
      width: calc(100% - 64px);
    }
  }
}

.navItems {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  width: 40%;
  @include breakpoint-up('sm') {
    margin: 0 -15px;
  }
  &.navUtilities {
    justify-content: flex-end;
  }
  .navItem {
    margin: 0 12px;
    @include breakpoint-up('sm') {
      margin: 0 15px;
    }
    @include breakpoint-down("sm") {
      white-space: nowrap;
    }
    cursor: pointer;
    &:hover {
      svg {
        * {
          fill: $salmon;
        }
      }
    }
  }
  svg {
    width: 17px;
    height: 17px;
    @include breakpoint-up('sm') {
      width: 24px;
      height: 24px;
    }
    * {
      transition:
        0.2s ease-in-out fill,
        0.2s ease-in-out stroke;
    }
  }
}

.navLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 48px;

  .navLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $sitkablue;
    width: 80px;
    height: 56px;
    position: absolute;
    top: 0;
    z-index: 3001;

    @include breakpoint-up('sm') {
      background-color: $sitkablue;
      width: 170px;
      height: 120px;
    }
    @include breakpoint-up('md') {
      background-color: $sitkablue;
      width: 170px;
      height: 120px;
    }
  }
  a {
    display: flex;
    position: relative;
    width: 61px;
    height: 40px;
    @include breakpoint-up('sm') {
      width: 130px;
      height: 84px;
    }
    @include breakpoint-up('md') {
      width: 130px;
      height: 84px;
    }
  }
}

.navItem {
  margin: 0 12px;
  a:not(.navButton) {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    @include breakpoint-up('lg') {
      font-size: 16px;
      line-height: 23px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .navButton {
    font-size: 14px;
    line-height: 22px;
    min-width: 200px;
    height: 48px;
    @include breakpoint-up('lg') {
      font-size: 18px;
      min-width: 260px;
    }
  }
  .navIconButton {
    display: flex;
    background: transparent;
    padding: 0;
    border: none;
  }
}

.accountMenuEnter,
.customerServiceInfoEnter {
  opacity: 0;
}
.accountMenuEnterActive,
.customerServiceInfoEnterActive {
  opacity: 1;
}
.accountMenuEnterDone,
.customerServiceInfoEnterDone {
  opacity: 1;
}
.accountMenuExit,
.customerServiceInfoExit {
  opacity: 0;
}

.accountMenuModal,
.customerServiceInfoModal,
.customerShippingInfoModal {
  transition: opacity 0.125s ease-in-out;
}

.accountMenu {
  right: 96px;
}

.customerServiceInfo {
  right: 60px;
  max-width: 400px;
}

.customerShippingInfo {
  right: 15px;
  max-width: 400px;
}

.accountMenu,
.customerServiceInfo,
.customerShippingInfo {
  position: absolute;
  top: 66px;
  background-color: $alabaster2;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  z-index: 2001;
}

.customerShippingInfo {
  background-color: $alabaster2;
  top: 66px;
  border-radius: 12px;

  min-width: 100px;
  border: 1px solid $black10;
}

.customerShippingInfo::before,
.customerShippingInfo::after {
  content: '';
  position: absolute;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.customerShippingInfo::before {
  top: -10px;
  border-bottom: 10px solid $black10;
}

.customerShippingInfo::after {
  top: -9px;
  right: 20px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $alabaster2;
}

.accountMenuOverlay,
.customerServiceInfoOverlay {
  width: 100%;
  height: calc(1000vh - 84px);
  position: absolute;
  top: 84px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(7px);
  z-index: 2000;
}

.accountMenuContent,
.customerServiceInfoContent,
.customerShippingInfoContent {
  padding: 50px 35px;
  h4 {
    margin-bottom: 10px;
  }
  p:last-of-type {
    margin-bottom: 50px;
  }
  ul {
    list-style: none;
  }
  li {
    margin: 0 0 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.customerShippingInfoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 15px 35px;
}

.freeShippingText{
  max-width: 140px;
  text-align: center;
  font-weight: 500;
  font-family: $bebas;
}

.minimumOrderText {
  margin-bottom: 0 !important;
}

.sm-desktop {
  display: block !important;
}
@include breakpoint-down(sm) {
  .sm-desktop {
    display: none !important;
  }
}

.lg-desktop {
  display: none !important;
}
@include breakpoint-down(sm) {
  .lg-desktop {
    display: block !important;
  }
}

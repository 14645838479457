.image-container {
  width: 100%;
  position: relative;
  > span {
    position: unset !important;
  }
}

.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.gift-banner {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #163144;
  color: white;
  padding: 2px 10px;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
@import '@/styles/_shared.scss';

.hits-wrap {
    margin-top: 26px;

    .hits-header {
        display: flex;
        justify-content: space-between;

        h6 {
            color: rgba(22, 49, 68, 0.6);
            padding-bottom: 12px;
        }

        a {
            font-weight: 500;
            color: $salmon;
        }

        span {
            color: $sitkablue;
        }
    }

    .hits-items {
        border-top: 1px solid $black20;
        padding-top: 18px;

        a {
            display: block;
            text-decoration: none;
        }

        h6 {
            margin-bottom: 12px;
        }
    }
}
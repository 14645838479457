.order-gift-card-form {
  p {
    font-size: 12px;
    @include breakpoint-up('sm') {
      font-size: 14px;
    }
  }
}

.order-gift-card-form__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  h3 {
    font-size: 14px;
    color: $sitkablue;
    @include breakpoint-up('sm') {
      font-size: 16px;
      color: $sitkablue;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    @include breakpoint-up('sm') {
      width: 25px;
      height: 25px;
    }
    * {
      fill: $sitkablue;
      @include breakpoint-up('sm') {
        fill: $sitkablue;
      }
    }
  }
}

.gift-card-form {
  display: flex;
  flex-direction: column;

  .gift-card-form-input {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include breakpoint-down('xs') {
      flex-direction: column;
    }

    p {
      font-size: 16px;
      @include breakpoint-up('sm') {
        margin: auto;
      }
    }

    .input-group {
      margin: 0;
      height: 48px;
    }
    .input-field__input {
      height: 48px;
    }
  }
}

.gift-card-form__btn {
  max-width: 253px;
  margin: 12px 0 0;
  @include breakpoint-up('sm') {
    margin: 0 0 0 20px;
  }
}

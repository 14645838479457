.select-field {
  position: relative;
  &--disabled {
    opacity: 0.4;
  }
  &--alert {
    outline-color: $error;
    .field__message {
      color: $error;
    }
  }
}
